import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our very forward thinking partner MasterCard just launched a `}<a parentName="p" {...{
        "href": "https://www.partnersinprepaid.com/index.html?maincategory=HOME"
      }}>{`new portal`}</a>{` to propel new growth in prepaid. As an intro to the site, you can check out an `}<a parentName="p" {...{
        "href": "http://t.co/drd2iIH"
      }}>{`interview they did with me`}</a>{` at the Prepaid Expo.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "474px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "96.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEeklEQVQ4y2N48ODx+QtX79x5eO3arcdP3jx99v7R4zefPn//////v79//4HBfzD4hwQgIgzHT91etebEmg2n1mw41dG7pbV7U23T2pOn7/7////v37//kQBcD9wghh3btzc1Nc+eNbOlpXXevLlz585taW3p6uqZNm1GU3Nza1v71CmTZ89dMHf27Llz53Z199Q3NNTXNz599hxk84UL51avXrVj+7YN69dt27Z1166dGzdu2Lpl84EDBzZuWL9+3drt27fs3bd3797d+/bu3bJ184YN6zdu3HD71s1PHz8wLF26MCc7qbAwKyTIz9/P09rSxMrc0NPJMj3ctTo/sbG+rK6muKmhPCc7JTTEPyE+Mj4+uiAzvijWeemsPobZM6eGh3gnJ0bGxoTp6mhwszPzcLCwMTE46ClkJoblZiXnZiXl56TkZiamJkWlJkanJMZkpcVnJIbPnzOdYe6c6XHRwTnZKfFxEQqykopSokoyYtoKEile1iV5aaXFOcUFGYX5GQX5GYUFmXm5qTmZSTlZSRlpcYsWzmWYPXuau4ttSriXm7WhED+vvKSIjKiAiZpsgb9VQpCnr69neIhvYqh7cphbTIBjRIh3UIBnaLC3t6djb08Hw+xZ0/y8XRKjgw21Vbg5WMUE+cUFeF11FUoD7GNDAwL9vIICvMN8XYI9bfycjD0cLZztzN2drOwtDbvaWxhmz5wWHRmYmZGooiTPycYsIcRrq6PkbaAUbG+SEBOen52akhgdHRkUFODp5mRtZaxnZaJrb2Vsbao7obcLFGBhIT5mRrpignwSIkIW2kqNiR5tyR62usrKMtLhQb4JcWFhQV6ujpa2FgaRYa5eHraWxnpGWoqdbY0M06dN8vZ0VFeSVZOV1lCQM1BTcTbRzQty8DbXkhEVNtPXiosJDQn0sDLVj/e3XzivrSAjUldVTkdOsLm2nKGvt9PO2sRUV8tIXVVNTkZdXkZXTcVCV8PXUltXTcFAUznYz8PX183HyWrVtIoLR+emhbiaGGjrqMs31dcwtDbXa6jK6aoqaspKK0uKq8hIGmoq6asqKIgLifLzSgkL2JgbpIZ7FoY41aWG1FdkuNsbmxpoaqnINNRVMXS0t+lqa5gaGmioKJsa6JoY6GmpqmmqqKgqKaqoKGhrqFsa6Vcn+pXG+Froa5mZGNpYmtnbWOrpaLS3tjBs3bq1pKSksbGxvq6hrrYeTtbX1Nc2VNXUVddW19XW1nd0dXX39LS1tdXXNTQ2NFVXV2/bvo3hzevX9+8/vHPnzt07dx/cv3/zxs27d+/eunXrwcNHd+/cffTo8b179+7fu/fs6dN79+7fuX373t27d+7cvXHjxsNHjxju37t3/vyFc2fPXbxw8dKlS6dOnT59+syFCxdOnTp94eJFkNS58+fOnTt/4cK58+cvXrh4+vTpkydP3rh58/r16wyvXr26cePGgweP/vz9+/Pnz2/fvn3/9u3Xr1/fvn378ePH9+/fv4LBr1+/fv78+evXLxDny5cfP0EAAB3pOxn+vuB8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/b241157538450ef4abbea7077cd1f3c1/7f61c/arlyn-mc-partnersinprepaid_videostill2.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/b241157538450ef4abbea7077cd1f3c1/01944/arlyn-mc-partnersinprepaid_videostill2.webp 474w"],
            "sizes": "(max-width: 474px) 100vw, 474px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/b241157538450ef4abbea7077cd1f3c1/e17e5/arlyn-mc-partnersinprepaid_videostill2.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/b241157538450ef4abbea7077cd1f3c1/5595f/arlyn-mc-partnersinprepaid_videostill2.png 474w"],
            "sizes": "(max-width: 474px) 100vw, 474px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/b241157538450ef4abbea7077cd1f3c1/5595f/arlyn-mc-partnersinprepaid_videostill2.png",
            "alt": "arlyn mc partnersinprepaid videostill2",
            "title": "arlyn mc partnersinprepaid videostill2",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<a parentName="p" {...{
        "href": "http://t.co/drd2iIH"
      }}>{` `}</a></p>
    <p>{`The portal, designed to foster collaboration between the various players in the prepaid value chain, highlights research, best practices and news on innovation, regulation and technology.`}</p>
    <p>{`In perusing the site today, it occurred to me how unusual the prepaid industry is. It is unique in that there`}{`’`}{`s so little vertical integration. Rather, companies collaborate and outsource core components of their business and share in the upside with their prepaid partners. (This explains the portal name: Partners in Prepaid.)`}</p>
    <p>{`Investors always ask us the question: "Why can`}{`’`}{`t Green Dot/NetSpend/insert Program Manager name here do this themselves?" to which we always respond, "There's a big difference between can and will". Which I still stand by.`}</p>
    <p>{`But today I`}{`’`}{`m adding a new answer to the list: "Prepaid, by design, is built on outsourcing".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      